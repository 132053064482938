import moment from 'moment';
import {
  OnboardingSectionType,
  OnboardingTask,
  OnboardingTaskType,
} from 'services/constants';

export const getUrlFromOnboardingKeyType = (
  itemType: OnboardingSectionType | OnboardingTaskType
) => itemType.toLowerCase().split('_').join('-');

// revert type back from URL
export const getOnboardingKeyTypeFromUrl = <
  T extends OnboardingSectionType | OnboardingTaskType
>(
  itemKey: string
) => itemKey.toUpperCase().split('-').join('_') as T;

// Used as second line for tasks list in section.
export const getTaskPreselectedText = (task: OnboardingTask) => {
  switch (task.type) {
    case OnboardingTaskType.organizationName:
      return task.data.tradeName.value || '';
    case OnboardingTaskType.registeredAddress: {
      // todo: logic not provided yet
      const addressArray = [];
      if (task.data.streetName.value)
        addressArray.push(
          task.data.streetName.value +
            (task.data.streetNumber.value
              ? ` ${task.data.streetNumber.value}`
              : '')
        );
      if (task.data.postalCode.value)
        addressArray.push(task.data.postalCode.value);
      return addressArray.join(', ') || '';
    }
    case OnboardingTaskType.registeredNumber:
      return task.data.registrationNumber.value || '';
    case OnboardingTaskType.foundationDate:
      return task.data.foundationDate.value
        ? moment(task.data.foundationDate.value).format('DD.MM.YYYY')
        : '';
    case OnboardingTaskType.vatId:
      return task.data.vatId.value || '';

    default:
      return '';
  }
};

export const getTaskNavigationLinks = (
  tasks: OnboardingTask[],
  currentType?: OnboardingTaskType
) => {
  if (!currentType) return { prevTypeUrl: '', nextTypeUrl: '' };

  const types = tasks.map((task) => task.type);
  const currentPositionIndex = types.findIndex((type) => type === currentType);
  if (currentPositionIndex === -1)
    return {
      prevTypeUrl: '',
      nextTypeUrl: '',
    };
  return {
    prevTypeUrl:
      currentPositionIndex > 0
        ? getUrlFromOnboardingKeyType(types[currentPositionIndex - 1])
        : '',
    nextTypeUrl:
      currentPositionIndex < types.length - 1
        ? getUrlFromOnboardingKeyType(types[currentPositionIndex + 1])
        : '',
  };
};
