import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { getOnboardingKeyTypeFromUrl } from 'domains/onboarding/utils';
import { Breadcrumbs, Link, Typography } from 'elements';
import { OnboardingSectionType, OnboardingTaskType } from 'services/constants';
import { getPath } from 'services/utils';

const getBreadcrumbElement = (
  translation: string,
  link?: string,
  isLastElement?: boolean
) => {
  if (!isLastElement && link)
    return (
      <Link
        to={link}
        component={RouterLink}
        sx={{ textDecoration: 'none' }}
        color="textSecondary"
      >
        {translation}
      </Link>
    );

  return (
    <Typography variant="inherit" component="span" color="textPrimary">
      {translation}
    </Typography>
  );
};

const OnboardingBreadcrumbs = () => {
  const { t } = useTranslation();
  const { sectionKey, taskKey } = useParams<{
    sectionKey: string;
    taskKey?: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const currentTaskType = useMemo(
    () =>
      taskKey ? getOnboardingKeyTypeFromUrl<OnboardingTaskType>(taskKey) : '',
    [taskKey]
  );

  return (
    <Breadcrumbs>
      <Link
        to={generatePath(getPath('orgOnboarding'), {
          orgId: organization!.id,
        })}
        component={RouterLink}
        sx={{ textDecoration: 'none' }}
        color="textSecondary"
      >
        {t('orgOnboardingSectionPage.titleOverview')}
      </Link>

      {getBreadcrumbElement(
        t(`orgOnboardingSectionTitle.${currentSectionType}`),
        generatePath(getPath('orgOnboarding') + '/' + sectionKey, {
          orgId: organization!.id,
        }),
        !currentTaskType
      )}

      {currentTaskType &&
        getBreadcrumbElement(t(`orgOnboardingTaskTitle.${currentTaskType}`))}
    </Breadcrumbs>
  );
};

export default OnboardingBreadcrumbs;
