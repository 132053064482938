import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingBreadcrumbs } from 'domains/onboarding/components';
import {
  getOnboardingKeyTypeFromUrl,
  getTaskNavigationLinks,
} from 'domains/onboarding/utils';
import {
  Box,
  LoaderWithOverlay,
  MobileStepperProgress,
  Typography,
} from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import { ContentContainer, PageContent, PageHeader } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
  OnboardingTaskFoundationDate,
  OnboardingTaskOrgName,
  OnboardingTaskRegAddress,
  OnboardingTaskRegNumber,
  OnboardingTaskType,
  OnboardingTaskVatId,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getPath } from 'services/utils';
import FoundationDateTask from './FoundationDateTask';
import RegisteredAddessTask from './RegisteredAddessTask';
import RegisteredNumberTask from './RegisteredNumberTask';
import TradeNameTask from './TradeNameTask';
import VatIdTask from './VatIdTask';

const OrganizationOnboardingTaskPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const showPageError = useShowPageError();
  const queryClient = useQueryClient();
  const { useGetOnboardingTasks } = useTanstackQuery();

  const { sectionKey, taskKey } = useParams<{
    sectionKey: string;
    taskKey: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const currentTaskType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingTaskType>(taskKey),
    [taskKey]
  );

  const { data: tasksData, isFetching, error } = useGetOnboardingTasks(
    organization!.id,
    currentSectionType
  );

  const tasks = tasksData?.tasks || [];
  const numberCompletedTasks = useMemo(
    () =>
      tasks.filter((task) => task.status === OnboardingItemStatus.completed)
        .length,
    [tasks]
  );
  const shownTask = useMemo(
    () => tasks.find((task) => task.type === currentTaskType) || null,
    [tasks, currentTaskType]
  );

  const { prevTypeUrl, nextTypeUrl } = useMemo(
    () => getTaskNavigationLinks(tasks, shownTask?.type),
    [tasks, shownTask]
  );

  useEffect(() => {
    if (error) {
      logError(error);
      showPageError();
    }
  }, [error]);

  const onTaskUpdate = () => {
    queryClient.invalidateQueries(
      onboardingKeys.tasks(organization!.id, currentSectionType)
    );
    queryClient.invalidateQueries(onboardingKeys.sections(organization!.id));
    const redirectUrl = generatePath(
      nextTypeUrl
        ? `${getPath('orgOnboarding')}/${sectionKey}/${nextTypeUrl}`
        : `${getPath('orgOnboarding')}/${sectionKey}`,
      {
        orgId: organization!.id,
      }
    );
    history.push(redirectUrl);
  };

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs />

          <Box mt={5}>
            <Typography variant="subtitle2">
              {t('orgOnboardingTaskPage.stepper', {
                completed: numberCompletedTasks,
                total: tasks.length,
              })}
            </Typography>

            <MobileStepperProgress
              steps={tasks.length}
              activeStep={numberCompletedTasks}
              nextButton={null}
              backButton={null}
              sx={{
                px: 0,
                '.MuiMobileStepper-progress': {
                  width: '100%',
                },
              }}
            />
          </Box>
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column">
        <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
          {shownTask &&
            {
              [OnboardingTaskType.organizationName]: (
                <TradeNameTask
                  task={shownTask as OnboardingTaskOrgName}
                  prevTypeUrl={prevTypeUrl}
                  nextTypeUrl={nextTypeUrl}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.registeredAddress]: (
                <RegisteredAddessTask
                  task={shownTask as OnboardingTaskRegAddress}
                  prevTypeUrl={prevTypeUrl}
                  nextTypeUrl={nextTypeUrl}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.registeredNumber]: (
                <RegisteredNumberTask
                  task={shownTask as OnboardingTaskRegNumber}
                />
              ),
              [OnboardingTaskType.foundationDate]: (
                <FoundationDateTask
                  task={shownTask as OnboardingTaskFoundationDate}
                />
              ),
              [OnboardingTaskType.vatId]: (
                <VatIdTask task={shownTask as OnboardingTaskVatId} />
              ),
            }[currentTaskType]}

          {!shownTask && !isFetching && <NothingFound />}
        </ContentContainer>

        <LoaderWithOverlay loading={isFetching} />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingTaskPage;
