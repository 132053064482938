import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  OnboardingSections,
  OnboardingSectionType,
  OnboardingTaskOrgName,
  OnboardingTaskRegAddress,
  OnboardingTasks,
  UpdateOrgNamePayload,
  UpdateOrgRegAddressPayload,
} from 'services/constants';
import { onboardingKeys } from './queryKeys';
import useRequest from './useRequest';

export const useOnboardingQuery = (request: ReturnType<typeof useRequest>) => {
  const useGetOnboardingSections = (
    organizationId: string,
    queryOptions?: UseQueryOptions<OnboardingSections>
  ) =>
    useQuery<OnboardingSections>({
      queryKey: onboardingKeys.sections(organizationId),
      queryFn: () =>
        request<OnboardingSections>(`/onboarding/${organizationId}/sections`),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingTasks = (
    organizationId: string,
    sectionType: OnboardingSectionType,
    queryOptions?: UseQueryOptions<OnboardingTasks>
  ) =>
    useQuery<OnboardingTasks>({
      queryKey: onboardingKeys.tasks(organizationId, sectionType),
      queryFn: () =>
        request<OnboardingTasks>(
          `/onboarding/${organizationId}/sections/tasks`,
          { params: { sectionType } }
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useUpdateOrgName = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskOrgName,
      unknown,
      UpdateOrgNamePayload
    >
  ) =>
    useMutation<OnboardingTaskOrgName, unknown, UpdateOrgNamePayload>({
      mutationFn: ({
        organizationId,
        taskId,
        tradeName,
      }: UpdateOrgNamePayload) =>
        request<OnboardingTaskOrgName>(
          `/onboarding/${organizationId}/tasks/organization-name/${taskId}`,
          {
            method: 'put',
            data: { tradeName },
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredAddress = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegAddress,
      unknown,
      UpdateOrgRegAddressPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-address/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  return {
    useGetOnboardingSections,
    useGetOnboardingTasks,
    useUpdateOrgName,
    useUpdateRegisteredAddress,
  };
};
