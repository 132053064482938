import { ComponentType, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SecurityKeySvgIcon } from 'assets/icons/interface/securityKey.svg';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import { IssueCardDialog } from 'domains/card/dialogs';
import {
  ChangeEmailDialog,
  DeactivateMemberDialog,
  EditMemberDialog,
  EditMemberRolesAndPermissionsDialog,
  ReactivateMemberDialog,
  ResetPhoneNumberDialog,
  SmsLogsDialog,
  UnpairSecurityKeyDialog,
} from 'domains/member/dialogs';
import {
  Box,
  CheckCircleIcon,
  CircularProgress,
  EnvelopeSimpleIcon,
  FadersHorizontalIcon,
  IconButton,
  ListBulletsIcon,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  PencilSimpleIcon,
  PhoneIcon,
  PlusIcon,
  SvgIconProps,
  Tooltip,
  XCircleIcon,
} from 'elements';
import { Card, MemberDetails, MemberStatus, Team } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

type Props = {
  cards: Card[] | null;
  managerTeam?: Team | undefined;
  onCardIssue: () => void;
  onUpdate: (member: MemberDetails, cards?: Card[]) => void;
  isGlobalUsersPage?: boolean;
  MenuIcon?: ComponentType<SvgIconProps>;
} & (
  | { member: MemberDetails } // regular sync flow
  | {
      member: MemberDetails | null;
      isAsync: boolean;
      isLoading: boolean;
      getMember: () => void;
      onMenuItemClick: () => void;
    } // async flow, when member details should be fetched asynchronously
);

const CustomSecurityKeySvgIcon = styled(SecurityKeySvgIcon)`
  width: ${({ theme }) => theme.spacing(2.5)};
  height: ${({ theme }) => theme.spacing(2.5)};
`;

const MemberDetailsMenu = ({
  member,
  cards,
  managerTeam,
  onCardIssue,
  onUpdate,
  isGlobalUsersPage,
  MenuIcon = FadersHorizontalIcon,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const teams = useActiveTeams();
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();
  const { smsDeliveryLogsEnabled } = useFlags();
  const canUser = useCanUser();
  const isAsync = 'isAsync' in props ? props.isAsync : false;
  const isLoading = 'isLoading' in props ? props.isLoading : false;
  const getMember = 'getMember' in props ? props.getMember : () => {};
  const onMenuItemClick =
    'onMenuItemClick' in props ? props.onMenuItemClick : () => {};

  const [isIssueCardDialogOpen, setIsIssueCardDialogOpen] = useState(false);
  const [isEditMemberDialogOpen, setIsEditMemberDialogOpen] = useState(false);
  const [
    isEditMemberRolesAndPermissionsDialogOpen,
    setIsEditMemberRolesAndPermissionsDialogOpen,
  ] = useState(false);
  const [isChangeEmailDialogOpen, setIsChangeEmailDialogOpen] = useState(false);
  const [
    isUnpairSecurityKeyDialogOpen,
    setIsUnpairSecurityKeyDialogOpen,
  ] = useState(false);
  const [
    isResetPhoneNumberDialogOpen,
    setIsResetPhoneNumberDialogOpen,
  ] = useState(false);
  const [isSmsLogsDialogOpen, setIsSmsLogsDialogOpen] = useState(false);
  const [
    isDeactivateMemberDialogOpen,
    setIsDeactivateMemberDialogOpen,
  ] = useState(false);
  const [
    isReactivateMemberDialogOpen,
    setIsReactivateMemberDialogOpen,
  ] = useState(false);

  const canIssueCard =
    !!member &&
    !isComplianceRiskWhiteLabelApp &&
    canUser('card:create') &&
    canUser('card:create-for-member', member, managerTeam);

  const canOpenPermissionsDialog =
    !!member &&
    canUser('member-permissions-role:view', member) &&
    !isGlobalUsersPage;

  const isMemberDetailsMenuVisible = (member: MemberDetails | null) =>
    !!member &&
    (canIssueCard ||
      canUser('member-details:change', member, managerTeam) ||
      canUser('member-teams:change', teams, managerTeam, member) ||
      canOpenPermissionsDialog ||
      canUser('member-email:change', member, managerTeam) ||
      canUser('member-multi-org-email:change', member) ||
      canUser('member-phone-number:change', member) ||
      canUser('member:deactivate', member, managerTeam) ||
      canUser('member:reactivate', member) ||
      (smsDeliveryLogsEnabled && canUser('member-sms-logs:view')));

  if (!isMemberDetailsMenuVisible(member) && !isAsync) return null;

  const onIssueCardSuccess = () => {
    setIsIssueCardDialogOpen(false);
    onCardIssue();
  };

  const onEditMemberSuccess = (member: MemberDetails) => {
    setIsEditMemberDialogOpen(false);
    onUpdate(member);
  };

  const onEditOrgAdminPermissionsSuccess = (member: MemberDetails) => {
    setIsEditMemberRolesAndPermissionsDialogOpen(false);
    onUpdate(member);
  };

  const onChangeEmailSuccess = (member: MemberDetails) => {
    setIsChangeEmailDialogOpen(false);
    onUpdate(member);
  };

  const onUnpairSecurityKeySuccess = () => {
    setIsUnpairSecurityKeyDialogOpen(false);
  };

  const onResetPhoneNumberSuccess = () => {
    setIsResetPhoneNumberDialogOpen(false);
  };

  const onDeactivateMemberSuccess = () => {
    setIsDeactivateMemberDialogOpen(false);
    onUpdate(
      {
        ...member!,
        status: MemberStatus.deactivated,
        teams: [],
      },
      []
    );
  };

  const onReactivateMemberSuccess = () => {
    setIsReactivateMemberDialogOpen(false);
    onUpdate({
      ...member!,
      status: MemberStatus.invited,
    });
  };

  return (
    <>
      <MenuContainer
        button={
          <IconButton>
            <MenuIcon />
          </IconButton>
        }
        onMenuOpen={() => {
          if (isAsync) getMember();
        }}
      >
        {isLoading || !member ? (
          <Box display="flex" justifyContent="center" minWidth={200} py={3}>
            <CircularProgress size="small" />
          </Box>
        ) : (
          <Box>
            {canIssueCard && (
              <MenuItem
                onClick={() => {
                  setIsIssueCardDialogOpen(true);
                  onMenuItemClick();
                }}
              >
                <ListItemIcon>
                  <PlusIcon />
                </ListItemIcon>
                {t('memberDetailsMenu.issueNewCard')}
              </MenuItem>
            )}
            {(canUser('member-details:change', member, managerTeam) ||
              canUser('member-teams:change', teams, managerTeam, member)) && (
              <Tooltip
                title={
                  member.embeddedPartnerMembership
                    ? t('memberDetailsMenu.embeddedPartnerEditTooltip')
                    : ''
                }
              >
                <div>
                  <MenuItem
                    onClick={() => {
                      setIsEditMemberDialogOpen(true);
                      onMenuItemClick();
                    }}
                    disabled={member.embeddedPartnerMembership}
                  >
                    <ListItemIcon>
                      <PencilSimpleIcon />
                    </ListItemIcon>
                    {t('memberDetailsMenu.editMemberDetails')}
                  </MenuItem>
                </div>
              </Tooltip>
            )}
            {canOpenPermissionsDialog && (
              <Tooltip
                title={
                  member.embeddedPartnerMembership
                    ? t('memberDetailsMenu.embeddedPartnerEditTooltip')
                    : ''
                }
              >
                <div>
                  <MenuItem
                    onClick={() => {
                      setIsEditMemberRolesAndPermissionsDialogOpen(true);
                      onMenuItemClick();
                    }}
                    disabled={member.embeddedPartnerMembership}
                  >
                    <ListItemIcon>
                      <PencilSimpleIcon />
                    </ListItemIcon>
                    {t('memberDetailsMenu.editMemberPermissions')}
                  </MenuItem>
                </div>
              </Tooltip>
            )}
            {(canUser('member-email:change', member, managerTeam) ||
              canUser('member-multi-org-email:change', member)) && (
              <MenuItem
                onClick={() => {
                  setIsChangeEmailDialogOpen(true);
                  onMenuItemClick();
                }}
              >
                <ListItemIcon>
                  <EnvelopeSimpleIcon />
                </ListItemIcon>
                {t('memberDetailsMenu.updateEmail')}
              </MenuItem>
            )}
            {canUser('member-security-key:unpair', member) && (
              <MenuItem
                onClick={() => {
                  setIsUnpairSecurityKeyDialogOpen(true);
                  onMenuItemClick();
                }}
              >
                <ListItemIcon>
                  <CustomSecurityKeySvgIcon />
                </ListItemIcon>
                {t('memberDetailsMenu.unpairSecurityKey')}
              </MenuItem>
            )}
            {canUser('member-phone-number:change', member) && (
              <Tooltip
                title={
                  member.embeddedPartnerMembership
                    ? t('memberDetailsMenu.embeddedPartnerEditTooltip')
                    : ''
                }
              >
                <div>
                  <MenuItem
                    onClick={() => {
                      setIsResetPhoneNumberDialogOpen(true);
                      onMenuItemClick();
                    }}
                    disabled={member.embeddedPartnerMembership}
                  >
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    {t('memberDetailsMenu.resetPhoneNumber')}
                  </MenuItem>
                </div>
              </Tooltip>
            )}
            {smsDeliveryLogsEnabled && canUser('member-sms-logs:view') && (
              <MenuItem
                onClick={() => {
                  setIsSmsLogsDialogOpen(true);
                  onMenuItemClick();
                }}
              >
                <ListItemIcon>
                  <ListBulletsIcon />
                </ListItemIcon>
                {t('memberDetailsMenu.smsLogs')}
              </MenuItem>
            )}
            {canUser('member:deactivate', member, managerTeam) && (
              <MenuItem
                onClick={() => {
                  setIsDeactivateMemberDialogOpen(true);
                  onMenuItemClick();
                }}
              >
                <ListItemIcon>
                  <XCircleIcon />
                </ListItemIcon>
                {t('memberDetailsMenu.deactivateMember')}
              </MenuItem>
            )}
            {canUser('member:reactivate', member) && (
              <Tooltip
                title={
                  member.embeddedPartnerMembership
                    ? t('memberDetailsMenu.embeddedPartnerReactivateTooltip')
                    : ''
                }
              >
                <div>
                  <MenuItem
                    onClick={() => {
                      setIsReactivateMemberDialogOpen(true);
                      onMenuItemClick();
                    }}
                    disabled={member.embeddedPartnerMembership}
                  >
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    {t('memberDetailsMenu.reactivateMember')}
                  </MenuItem>
                </div>
              </Tooltip>
            )}
          </Box>
        )}
      </MenuContainer>

      {member && (
        <>
          <IssueCardDialog
            open={isIssueCardDialogOpen}
            onClose={() => setIsIssueCardDialogOpen(false)}
            onSuccess={onIssueCardSuccess}
            member={member}
            managerTeam={managerTeam}
          />
          <EditMemberDialog
            open={isEditMemberDialogOpen}
            onClose={() => setIsEditMemberDialogOpen(false)}
            member={member}
            managerTeam={managerTeam}
            onSuccess={onEditMemberSuccess}
          />
          <EditMemberRolesAndPermissionsDialog
            member={member}
            open={isEditMemberRolesAndPermissionsDialogOpen}
            onClose={() => setIsEditMemberRolesAndPermissionsDialogOpen(false)}
            onSuccess={onEditOrgAdminPermissionsSuccess}
          />
          <ChangeEmailDialog
            open={isChangeEmailDialogOpen}
            onClose={() => setIsChangeEmailDialogOpen(false)}
            member={member}
            onSuccess={onChangeEmailSuccess}
          />
          <UnpairSecurityKeyDialog
            open={isUnpairSecurityKeyDialogOpen}
            onClose={() => setIsUnpairSecurityKeyDialogOpen(false)}
            member={member}
            onSuccess={onUnpairSecurityKeySuccess}
          />
          <ResetPhoneNumberDialog
            open={isResetPhoneNumberDialogOpen}
            onClose={() => setIsResetPhoneNumberDialogOpen(false)}
            onSuccess={onResetPhoneNumberSuccess}
            member={member}
          />
          <SmsLogsDialog
            open={isSmsLogsDialogOpen}
            onClose={() => setIsSmsLogsDialogOpen(false)}
            member={member}
          />
          <DeactivateMemberDialog
            open={isDeactivateMemberDialogOpen}
            member={member}
            cards={cards}
            onClose={() => setIsDeactivateMemberDialogOpen(false)}
            onSuccess={onDeactivateMemberSuccess}
          />
          <ReactivateMemberDialog
            open={isReactivateMemberDialogOpen}
            member={member}
            onClose={() => setIsReactivateMemberDialogOpen(false)}
            onSuccess={onReactivateMemberSuccess}
          />
        </>
      )}
    </>
  );
};

export default MemberDetailsMenu;
