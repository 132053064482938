import {
  CalendarBlankIcon,
  IdentificationCardIcon,
  MapPinIcon,
  NumberCircleOneIcon,
  ReadCvLogoIcon,
  TrademarkRegisteredIcon,
} from 'elements';
import { OnboardingSectionType, OnboardingTaskType } from 'services/constants';

export const SectionIconByType = {
  [OnboardingSectionType.companyOverview]: ReadCvLogoIcon,
};

export const TaskIconByType = {
  [OnboardingTaskType.organizationName]: TrademarkRegisteredIcon,
  [OnboardingTaskType.registeredAddress]: MapPinIcon,
  [OnboardingTaskType.registeredNumber]: NumberCircleOneIcon,
  [OnboardingTaskType.foundationDate]: CalendarBlankIcon,
  [OnboardingTaskType.vatId]: IdentificationCardIcon,
};
