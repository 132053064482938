import { useEffect, useMemo } from 'react';
import flatten from 'lodash/flatten';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  OrganizationOnboardingPage,
  OrganizationOnboardingSectionPage,
  OrganizationOnboardingTaskPage,
} from 'domains/onboarding/pages';
import { getUrlFromOnboardingKeyType } from 'domains/onboarding/utils';
import { LoaderWithOverlay } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import withOnboardingRedirection from './withOnboardingRedirection';

const OrganizationOnboardingRoutes = () => {
  const { path } = useRouteMatch();
  const showPageError = useShowPageError();
  const {
    state: { organization },
  } = useGlobalState();
  const { useGetOnboardingSections } = useTanstackQuery();
  const { data, isInitialLoading, error } = useGetOnboardingSections(
    organization!.id
  );

  useEffect(() => {
    if (error) {
      logError(error);
      showPageError();
    }
  }, [error]);

  const sectionPaths = useMemo(
    () =>
      data?.sections.map(
        (section) => `${path}/${getUrlFromOnboardingKeyType(section.type)}`
      ) || [],
    [data?.sections]
  );

  const tasksPaths = useMemo(
    () =>
      flatten(
        data?.sections.map((section) =>
          section.tasks?.map(
            (task) =>
              `${path}/${getUrlFromOnboardingKeyType(
                section.type
              )}/${getUrlFromOnboardingKeyType(task.type)}`
          )
        ) || []
      ),
    [data?.sections]
  );

  if (isInitialLoading) return <LoaderWithOverlay loading />;

  return (
    <Switch>
      <Route path={[...sectionPaths, ...tasksPaths]}>
        <Switch>
          <Route path={tasksPaths}>
            <Route
              path={`${path}/:sectionKey/:taskKey`}
              component={OrganizationOnboardingTaskPage}
            />
          </Route>
          <Route
            exact
            path={`${path}/:sectionKey`}
            component={OrganizationOnboardingSectionPage}
          />
          <Redirect to={path} />
        </Switch>
      </Route>
      <Route
        exact
        path={path}
        render={(props) => (
          <OrganizationOnboardingPage
            sections={data?.sections || null}
            {...props}
          />
        )}
      />
      <Redirect to={path} />
    </Switch>
  );
};

export default withPageConfig(
  withOnboardingRedirection(OrganizationOnboardingRoutes),
  {
    permission: 'org-onboarding-page:visit',
  }
);
