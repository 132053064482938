import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  MISSING_SUPPLIER_OPTION,
  MISSING_TEAM_OPTION,
  NONE_VALUE,
  QueryParams,
} from 'domains/transaction/pages/ExportPage/utils';
import { Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { PageFiltersComponent } from 'layout';
import {
  ExpenseType,
  ProjectStatus,
  SubcategoryStatus,
  Supplier,
  TransactionReceiptStatus,
  TransactionReviewStatus,
  TransactionSimpleType,
  VatRateStatus,
} from 'services/constants';
import FilterChips from './FilterChips';
import FiltersDrawer from './FiltersDrawer';
import usePreselectedData, {
  initialPreselectedDataState,
  PreselectedDataState,
} from './usePreselectedData';

export type SupplierType = Supplier | typeof MISSING_SUPPLIER_OPTION | null;

export const getSupplierParamValue = (supplierValue: SupplierType) => {
  if (supplierValue === MISSING_SUPPLIER_OPTION) return supplierValue;
  return supplierValue?.id || '';
};

export interface FilterProps {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  transactionsCount: number;
}

export interface FiltersState {
  type: TransactionSimpleType[];
  receipt: TransactionReceiptStatus | '';
  supplier: SupplierType;
  subcategory:
    | SubcategoryStatus.missing
    | Omit<string, SubcategoryStatus.missing>;
  vatRate: VatRateStatus.missing | Omit<VatRateStatus.missing, string>;
  project: ProjectStatus.missing | Omit<ProjectStatus.missing, string>;
  team: typeof MISSING_TEAM_OPTION | Omit<typeof MISSING_TEAM_OPTION, string>;
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  cardAccountId: string;
  expenseType: ExpenseType | '';
}

const initialFiltersState: FiltersState = {
  type: [],
  receipt: '',
  supplier: null,
  subcategory: '',
  vatRate: '',
  project: '',
  team: '',
  fromDate: null,
  toDate: null,
  reviewStatus: '',
  cardAccountId: '',
  expenseType: '',
};

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  transactionsCount,
}: FilterProps) => {
  const { t } = useTranslation();
  const { preselectedData, onPreselectedChange } = usePreselectedData(
    params.supplier
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filtersState, setFiltersState] = useState<FiltersState>(
    initialFiltersState
  );

  useEffect(() => {
    if (isFilterOpen)
      setFiltersState({
        type: params.type,
        receipt: params.receipt,
        supplier: preselectedData.supplier,
        subcategory: params.subcategory,
        vatRate: params.vatRate,
        project: params.project,
        team: params.team,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        cardAccountId: params.cardAccountId,
        expenseType: params.expenseType,
      });
  }, [params, preselectedData.supplier, isFilterOpen]);

  const onFiltersApply = () => {
    const preselectedUpdatedState: Partial<PreselectedDataState> = {
      supplier: filtersState.supplier,
    };
    if (filtersState.supplier !== preselectedData.supplier)
      preselectedUpdatedState.supplierInvalid = false;

    onPreselectedChange(preselectedUpdatedState);

    setParam(
      Object.entries({
        ...filtersState,
        supplier: getSupplierParamValue(filtersState.supplier),
        fromDate: filtersState.fromDate?.startOf('day')?.format(),
        toDate: filtersState.toDate?.endOf('day')?.format(),
      })
    );
    setIsFilterOpen(false);
  };

  const onFilterReset = () => {
    onPreselectedChange(initialPreselectedDataState);
    setParam(Object.entries(initialFiltersState));
    setIsFilterOpen(false);
  };

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled || preselectedData.areFiltersLoading}
      >
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', { count: transactionsCount })}
        </Typography>
      </PageFiltersComponent>

      <FilterChips
        params={params}
        selectedFiltersCount={selectedFiltersCount}
        setParam={setParam}
        preselectedData={preselectedData}
        onPreselectedReset={onPreselectedChange}
      />

      <FiltersDrawer
        open={isFilterOpen}
        filtersState={filtersState}
        supplierHasError={preselectedData.supplierInvalid}
        onFiltersApply={onFiltersApply}
        onFilterReset={onFilterReset}
        setFilters={(newState) =>
          setFiltersState((prevState) => ({ ...prevState, ...newState }))
        }
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
