import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingBreadcrumbs,
  TaskListItem,
} from 'domains/onboarding/components';
import { getOnboardingKeyTypeFromUrl } from 'domains/onboarding/utils';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import { List, LoaderWithOverlay, Typography } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import { OnboardingSectionType } from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';

const OrganizationOnboardingSectionPage = () => {
  const { t } = useTranslation();
  const { sectionKey } = useParams<{ sectionKey: string }>();
  const {
    state: { organization },
  } = useGlobalState();
  const showPageError = useShowPageError();
  const {
    useGetOnboardingSections,
    useGetOnboardingTasks,
  } = useTanstackQuery();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const { isFetching: areSectionsLoading } = useGetOnboardingSections(
    organization!.id
  );
  const {
    data: tasksData,
    isFetching: areTasksLoading,
    error,
  } = useGetOnboardingTasks(organization!.id, currentSectionType);
  const tasks = tasksData?.tasks || [];

  useEffect(() => {
    if (error) {
      logError(error);
      showPageError();
    }
  }, [error]);

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs />

          <PageTitle
            title={t(`orgOnboardingSectionTitle.${currentSectionType}`)}
          />

          <Typography mt={1} variant="body2" color="textSecondary">
            {t(`orgOnboardingSectionPage.description.${currentSectionType}`)}
          </Typography>
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column">
        {!!tasks.length && (
          <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
            <List>
              {tasks.map((task) => (
                <TaskListItem
                  key={task.id}
                  task={task}
                  sectionKey={sectionKey}
                />
              ))}
            </List>
          </ContentContainer>
        )}

        {!areTasksLoading && tasks.length === 0 && <NothingFound />}

        <PartnerLegalDisclaimer />

        <LoaderWithOverlay loading={areTasksLoading || areSectionsLoading} />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingSectionPage;
