import { OnboardingTaskVatId } from 'services/constants';
interface Props {
  task: OnboardingTaskVatId;
}

const VatIdTask = ({ task }: Props) => {
  return <div>VatIdTask</div>;
};

export default VatIdTask;
