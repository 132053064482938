import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Box, Button } from 'elements';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

interface Props {
  nextTypeUrl: string;
  prevTypeUrl: string;
  disabled: boolean;
  onSuccess: () => void;
}

const OnboardingTaskActions = ({
  nextTypeUrl,
  prevTypeUrl,
  disabled,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { sectionKey } = useParams<{
    sectionKey: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={4}
    >
      <Button
        variant="text"
        disabled={!prevTypeUrl}
        component={RouterLink}
        to={{
          pathname: generatePath(
            `${getPath('orgOnboarding')}/${sectionKey}/${prevTypeUrl}`,
            {
              orgId: organization!.id,
            }
          ),
        }}
      >
        {t('common.button.back')}
      </Button>

      <Box sx={{ 'a + button': { ml: 2 } }}>
        {nextTypeUrl && (
          <Button
            component={RouterLink}
            variant="text"
            to={{
              pathname: generatePath(
                `${getPath('orgOnboarding')}/${sectionKey}/${nextTypeUrl}`,
                {
                  orgId: organization!.id,
                }
              ),
            }}
          >
            {t('common.button.skip')}
          </Button>
        )}
        {canUser('org-onboarding-task:change') && (
          <Button disabled={disabled} onClick={onSuccess}>
            {t('common.button.continue')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingTaskActions;
