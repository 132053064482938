import { OnboardingTaskRegNumber } from 'services/constants';

interface Props {
  task: OnboardingTaskRegNumber;
}

const RegisteredNumberTask = ({ task }: Props) => {
  return <div>RegisteredNumberTask</div>;
};

export default RegisteredNumberTask;
