import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { AppWrapper } from 'components/App/AppWrapper';
import { queryClientOptions } from 'components/App/ExternalApp/layouts/OnboardingLayout';
import { env } from 'env';
import { onAuth0RedirectCallback } from 'services/utils';
import Routes from './Routes';

const queryClient = new QueryClient(queryClientOptions);

export const InternalApp = () => {
  const assetsFolderPath = {
    development: `${env.REACT_APP_ASSETS_URL}/images/dev`,
    staging: `${env.REACT_APP_ASSETS_URL}/images/staging`,
    prod: `${env.REACT_APP_ASSETS_URL}/images/v2`,
  }[env.REACT_APP_TARGET_ENV];

  return (
    <AppWrapper>
      <Helmet>
        <link
          type="image/x-icon"
          rel="shortcut icon"
          href={`${assetsFolderPath}/favicon.ico`}
        />
        <link
          type="image/png"
          sizes="16x16"
          rel="icon"
          href={`${assetsFolderPath}/favicon-16x16.png`}
        />
        <link
          type="image/png"
          sizes="32x32"
          rel="icon"
          href={`${assetsFolderPath}/favicon-32x32.png`}
        />
        <link
          type="image/png"
          sizes="96x96"
          rel="icon"
          href={`${assetsFolderPath}/favicon-96x96.png`}
        />
        <title>Pliant</title>
      </Helmet>
      {/* this is a temporary place for query client provider. It should be moved to
       *   AppWrapper for internal/external apps
       */}
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={env.REACT_APP_AUTH0_DOMAIN}
          clientId={env.REACT_APP_AUTH0_CLIENT_ID}
          audience={env.REACT_APP_AUTH0_AUDIENCE}
          redirectUri={window.location.origin + '/callback'}
          onRedirectCallback={onAuth0RedirectCallback}
        >
          <Routes />
        </Auth0Provider>
      </QueryClientProvider>
    </AppWrapper>
  );
};
