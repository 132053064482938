import { OnboardingTaskFoundationDate } from 'services/constants';

interface Props {
  task: OnboardingTaskFoundationDate;
}

const FoundationDateTask = ({ task }: Props) => {
  return <div>FoundationDateTask</div>;
};

export default FoundationDateTask;
