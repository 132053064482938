import { ComponentType } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingStatusBadge } from 'domains/onboarding/components';
import { getUrlFromOnboardingKeyType } from 'domains/onboarding/utils';
import {
  Avatar,
  CaretRightIcon,
  IconButton,
  Paper,
  PaperProps,
  SvgIconProps,
  Typography,
} from 'elements';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
} from 'services/constants';
import { getPath } from 'services/utils';

interface Props extends PaperProps {
  Icon: ComponentType<SvgIconProps>;
  status: OnboardingItemStatus;
  sectionType: OnboardingSectionType;
}

const SectionItem = ({
  children,
  Icon,
  status,
  sectionType,
  ...props
}: Props) => {
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Paper
      variant="outlined"
      sx={{ p: 2, display: 'flex', alignItems: 'center', '&+&': { mt: 2 } }}
      {...props}
    >
      <Avatar
        variant="rounded"
        sx={{ bgcolor: (theme) => theme.variables.warmGrey[200] }}
      >
        {Icon && <Icon color="primary" />}
      </Avatar>

      <Typography variant="h6" component="div" ml={2} flex={1}>
        {children}
      </Typography>

      <OnboardingStatusBadge status={status} />

      <IconButton
        component={Link}
        sx={{ ml: 2 }}
        to={{
          pathname: generatePath(
            `${getPath('orgOnboarding')}/${getUrlFromOnboardingKeyType(
              sectionType
            )}`,
            {
              orgId: organization!.id,
            }
          ),
        }}
      >
        <CaretRightIcon />
      </IconButton>
    </Paper>
  );
};

export default SectionItem;
