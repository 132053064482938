import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  Address,
  OnboardingTaskRegAddress,
  OnboardingTaskType,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import {
  getGenericErrorMsg,
  trimObjValues,
  validateAddress,
} from 'services/utils';

interface FormValues {
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: SupportedCountry | '';
  streetAdditional: string;
}

export interface Props {
  task: OnboardingTaskRegAddress;
  prevTypeUrl: string;
  nextTypeUrl: string;
  onUpdate: () => void;
}

const RegisteredAddessTask = ({
  task,
  prevTypeUrl,
  nextTypeUrl,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const { useUpdateRegisteredAddress } = useTanstackQuery();
  const { mutate: orgRegAddressMutate } = useUpdateRegisteredAddress({
    onSuccess: () => {
      if (!mounted.current) return;
      onUpdate();
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const {
    data: {
      city,
      country,
      postalCode,
      streetAdditional,
      streetName,
      streetNumber,
    },
  } = task;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      streetName: streetName.value ?? '',
      streetNumber: streetNumber.value ?? '',
      postalCode: postalCode.value ?? '',
      city: city.value ?? '',
      country: (country.value as SupportedCountry | null) ?? '',
      streetAdditional: streetAdditional.value ?? '',
    },
    validate: (values) => {
      const trimmedValues = trimObjValues(values);
      const objectToValidate: Partial<Address> = {};
      Object.keys(trimmedValues).forEach((item) => {
        const key = item as keyof Address;
        if (task.data[key].required || trimmedValues[key]) {
          if (key === 'country')
            objectToValidate[key] = trimmedValues[key] || undefined;
          else objectToValidate[key] = trimmedValues[key];
        }
      });

      const errors = validateAddress(objectToValidate, t, false, true) as {
        [k in keyof FormValues]?: string;
      };
      return errors;
    },
    onSubmit: (values) => {
      const trimmedValues = trimObjValues(values);
      orgRegAddressMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: trimmedValues,
      });
    },
  });

  const hasEmptyField =
    !formik.values.country.trim() ||
    !formik.values.city.trim() ||
    !formik.values.postalCode.trim() ||
    !formik.values.streetName.trim() ||
    !(
      formik.values.country === SupportedCountry.ie ||
      formik.values.streetNumber.trim()
    );

  return (
    <>
      <PageTitle
        pt={0}
        title={t(
          `orgOnboardingTaskTitle.${OnboardingTaskType.registeredAddress}`
        )}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled={formik.isSubmitting || !streetName.editable}
            label={t('orgOnboardingRegAddressTask.streetLabel')}
            placeholder={t('orgOnboardingRegAddressTask.streetPlaceholder')}
            {...formik.getFieldProps('streetName')}
            error={!!formik.errors.streetName}
            helperText={formik.errors.streetName}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={formik.isSubmitting || !streetNumber.editable}
            label={t('orgOnboardingRegAddressTask.streetNumberLabel')}
            placeholder={t(
              'orgOnboardingRegAddressTask.streetNumberPlaceholder'
            )}
            {...formik.getFieldProps('streetNumber')}
            error={!!formik.errors.streetNumber}
            helperText={formik.errors.streetNumber}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={formik.isSubmitting || !postalCode.editable}
            label={t('orgOnboardingRegAddressTask.postalCodeLabel')}
            placeholder={t('orgOnboardingRegAddressTask.postalCodePlaceholder')}
            {...formik.getFieldProps('postalCode')}
            error={!!formik.errors.postalCode}
            helperText={formik.errors.postalCode}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={formik.isSubmitting || !city.editable}
            label={t('orgOnboardingRegAddressTask.cityLabel')}
            placeholder={t('orgOnboardingRegAddressTask.cityPlaceholder')}
            {...formik.getFieldProps('city')}
            error={!!formik.errors.city}
            helperText={formik.errors.city}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth error={!!formik.errors.country}>
            <InputLabel id="address-country">
              {t('orgOnboardingRegAddressTask.countryLabel')}
            </InputLabel>
            <Select<SupportedCountry>
              displayEmpty
              disabled={formik.isSubmitting || !country.editable}
              labelId="address-country"
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <Typography color="text.disabled">
                      {t('orgOnboardingRegAddressTask.countryPlaceholder')}
                    </Typography>
                  );
                return t(`countries.${selected}`);
              }}
              {...formik.getFieldProps('country')}
              error={!!formik.errors.country}
            >
              {supportedCountries.map((slug) => {
                return (
                  <MenuItem key={slug} value={slug}>
                    {t(`countries.${slug}`)}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{formik.errors.country}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={formik.isSubmitting || !streetAdditional.editable}
            label={t('orgOnboardingRegAddressTask.streetAdditionalLabel')}
            placeholder={t(
              'orgOnboardingRegAddressTask.streetAdditionalPlaceholder'
            )}
            {...formik.getFieldProps('streetAdditional')}
            error={!!formik.errors.streetAdditional}
            helperText={formik.errors.streetAdditional}
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <OnboardingTaskActions
          prevTypeUrl={prevTypeUrl}
          nextTypeUrl={nextTypeUrl}
          disabled={formik.isSubmitting || hasEmptyField || !formik.dirty}
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default RegisteredAddessTask;
