import { createSvgIcon } from '@mui/material';
import {
  Archive,
  ArrowCircleDown,
  ArrowCounterClockwise,
  ArrowLineDown,
  ArrowLineUp,
  ArrowRight,
  ArrowsClockwise,
  ArrowsCounterClockwise,
  ArrowsInLineHorizontal,
  ArrowsLeftRight,
  ArrowsMerge,
  ArrowSquareOut,
  ArrowsSplit,
  Bank,
  Bell,
  Book,
  BookOpenText,
  Buildings,
  CalendarBlank,
  CalendarCheck,
  Cardholder,
  Cards,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Certificate,
  ChartPie,
  ChatCircleText,
  ChatTeardropDots,
  ChatTeardropText,
  Check,
  CheckCircle,
  CircleDashed,
  CircleNotch,
  Clock,
  ClockCountdown,
  CloudCheck,
  CloudSlash,
  CloudX,
  CodeBlock,
  Copy,
  CreditCard,
  Cube,
  CurrencyCircleDollar,
  CurrencyEur,
  DeviceMobileCamera,
  DotsThreeOutlineVertical,
  Download,
  DownloadSimple,
  Envelope,
  EnvelopeSimple,
  EnvelopeSimpleOpen,
  Export,
  Eye,
  EyeSlash,
  FadersHorizontal,
  FileArrowUp,
  FileCsv,
  FileMagnifyingGlass,
  FilePdf,
  FilePlus,
  Files,
  FileText,
  FileX,
  FlagPennant,
  Flask,
  FloppyDisk,
  FolderNotch,
  ForkKnife,
  FunnelSimple,
  Gavel,
  Gear,
  GearSix,
  Gift,
  GlobeHemisphereWest,
  HandCoins,
  HandsClapping,
  Handshake,
  HardDrives,
  HourglassHigh,
  HourglassMedium,
  IdentificationCard,
  Image,
  Info,
  Laptop,
  Lightning,
  Link,
  LinkBreak,
  List,
  ListBullets,
  Lock,
  LockOpen,
  MagnifyingGlass,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  MapPin,
  MegaphoneSimple,
  Minus,
  Money,
  MusicNotes,
  NumberCircleOne,
  Package,
  Palette,
  Paperclip,
  Password,
  PencilSimple,
  Percent,
  Phone,
  PhoneCall,
  PiggyBank,
  Plugs,
  PlugsConnected,
  Plus,
  Prohibit,
  ProjectorScreen,
  ProjectorScreenChart,
  Question,
  ReadCvLogo,
  Receipt,
  ReceiptX,
  RocketLaunch,
  ShieldCheck,
  ShieldWarning,
  ShoppingCart,
  SignOut,
  SlidersHorizontal,
  Smiley,
  SplitHorizontal,
  SquaresFour,
  Star,
  Storefront,
  SuitcaseSimple,
  Swap,
  ThumbsUp,
  TrademarkRegistered,
  Trash,
  Tray,
  UploadSimple,
  User,
  UserCircle,
  UserCirclePlus,
  UserGear,
  UserPlus,
  Users,
  UsersThree,
  Wallet,
  Warning,
  WarningCircle,
  WarningDiamond,
  X,
  XCircle,
  XSquare,
} from '@phosphor-icons/react';

export const ArchiveIcon = createSvgIcon(<Archive size={24} />, 'Archive');

export const ArrowCircleDownFilledIcon = createSvgIcon(
  <ArrowCircleDown size={24} weight="fill" />,
  'ArrowCircleDownFilled'
);

export const ArrowCircleDownIcon = createSvgIcon(
  <ArrowCircleDown size={24} />,
  'ArrowCircleDown'
);

export const ArrowCounterClockwiseIcon = createSvgIcon(
  <ArrowCounterClockwise size={24} weight="fill" />,
  'ArrowCounterClockwise'
);

export const ArrowLineDownIcon = createSvgIcon(
  <ArrowLineDown size={24} />,
  'ArrowLineDown'
);

export const ArrowLineUpIcon = createSvgIcon(
  <ArrowLineUp size={24} />,
  'ArrowLineUp'
);

export const ArrowRightIcon = createSvgIcon(
  <ArrowRight size={24} />,
  'ArrowRight'
);

export const ArrowsClockwiseIcon = createSvgIcon(
  <ArrowsClockwise size={24} />,
  'ArrowsClockwise'
);

export const ArrowsCounterClockwiseIcon = createSvgIcon(
  <ArrowsCounterClockwise size={24} />,
  'ArrowsCounterClockwise'
);

export const ArrowsInLineHorizontalIcon = createSvgIcon(
  <ArrowsInLineHorizontal size={24} />,
  'ArrowsInLineHorizontal'
);

export const ArrowsLeftRightIcon = createSvgIcon(
  <ArrowsLeftRight size={24} />,
  'ArrowsLeftRight'
);

export const ArrowsMergeIcon = createSvgIcon(
  <ArrowsMerge size={24} />,
  'ArrowsMerge'
);

export const ArrowSquareOutIcon = createSvgIcon(
  <ArrowSquareOut size={24} />,
  'ArrowSquareOut'
);

export const ArrowsSplitIcon = createSvgIcon(
  <ArrowsSplit size={24} />,
  'ArrowsSplit'
);

export const BankIcon = createSvgIcon(<Bank size={24} />, 'Bank');

export const BellIcon = createSvgIcon(<Bell size={24} />, 'Bell');

export const BookIcon = createSvgIcon(<Book size={24} />, 'Book');

export const BookOpenTextIcon = createSvgIcon(
  <BookOpenText size={24} />,
  'BookOpenText'
);

export const BuildingsIcon = createSvgIcon(
  <Buildings size={24} />,
  'Buildings'
);

export const CalendarBlankIcon = createSvgIcon(
  <CalendarBlank size={24} />,
  'CalendarBlank'
);

export const CalendarCheckIcon = createSvgIcon(
  <CalendarCheck size={24} />,
  'CalendarCheck'
);

export const CardholderIcon = createSvgIcon(
  <Cardholder size={24} />,
  'Cardholder'
);

export const CardsIcon = createSvgIcon(<Cards size={24} />, 'Cards');

export const CaretDownIcon = createSvgIcon(
  <CaretDown size={24} />,
  'CaretDown'
);

export const CaretLeftIcon = createSvgIcon(
  <CaretLeft size={24} />,
  'CaretLeft'
);

export const CaretRightIcon = createSvgIcon(
  <CaretRight size={24} />,
  'CaretRight'
);

export const CaretUpIcon = createSvgIcon(<CaretUp size={24} />, 'CaretUp');

export const CertificateIcon = createSvgIcon(
  <Certificate size={24} />,
  'Certificate'
);

export const ChartPieIcon = createSvgIcon(<ChartPie size={24} />, 'ChartPie');

export const ChatCircleTextIcon = createSvgIcon(
  <ChatCircleText size={24} />,
  'ChatCircleText'
);

export const ChatTeardropDotsIcon = createSvgIcon(
  <ChatTeardropDots size={24} />,
  'ChatTeardropDots'
);

export const ChatTeardropTextIcon = createSvgIcon(
  <ChatTeardropText size={24} />,
  'ChatTeardropText'
);

export const CheckCircleIcon = createSvgIcon(
  <CheckCircle size={24} />,
  'CheckCircle'
);

export const CheckIcon = createSvgIcon(<Check size={24} />, 'Check');

export const CircleDashedIcon = createSvgIcon(
  <CircleDashed size={24} />,
  'CircleDashed'
);

export const CircleNotchFilledIcon = createSvgIcon(
  <CircleNotch weight="fill" size={24} />,
  'CircleNotch'
);

export const ClockCountdownIcon = createSvgIcon(
  <ClockCountdown size={24} />,
  'ClockCountdown'
);

export const ClockIcon = createSvgIcon(<Clock size={24} />, 'Clock');

export const CloudCheckIcon = createSvgIcon(
  <CloudCheck size={24} />,
  'CloudCheck'
);

export const CloudSlashIcon = createSvgIcon(
  <CloudSlash size={24} />,
  'CloudSlash'
);

export const CloudXIcon = createSvgIcon(<CloudX size={24} />, 'CloudX');

export const CodeBlockIcon = createSvgIcon(
  <CodeBlock size={24} />,
  'CodeBlock'
);

export const CopyIcon = createSvgIcon(<Copy size={24} />, 'Copy');

export const CreditCardIcon = createSvgIcon(
  <CreditCard size={24} />,
  'CreditCard'
);

export const CubeIcon = createSvgIcon(<Cube size={24} />, 'Cube');

export const CurrencyCircleDollarIcon = createSvgIcon(
  <CurrencyCircleDollar size={24} />,
  'CurrencyCircleDollar'
);

export const CurrencyEurIcon = createSvgIcon(
  <CurrencyEur size={24} />,
  'CurrencyEur'
);

export const DeviceMobileCameraIcon = createSvgIcon(
  <DeviceMobileCamera size={24} />,
  'DeviceMobileCamera'
);

export const DotsThreeOutlineVerticalIcon = createSvgIcon(
  <DotsThreeOutlineVertical size={24} />,
  'DotsThreeOutlineVertical'
);

export const DownloadIcon = createSvgIcon(<Download size={24} />, 'Download');

export const DownloadSimpleIcon = createSvgIcon(
  <DownloadSimple size={24} />,
  'DownloadSimple'
);

export const EnvelopeIcon = createSvgIcon(<Envelope size={24} />, 'Envelope');

export const EnvelopeSimpleIcon = createSvgIcon(
  <EnvelopeSimple size={24} />,
  'EnvelopeSimple'
);

export const EnvelopeSimpleOpenIcon = createSvgIcon(
  <EnvelopeSimpleOpen size={24} />,
  'EnvelopeSimpleOpen'
);

export const ExportIcon = createSvgIcon(<Export size={24} />, 'Export');

export const EyeIcon = createSvgIcon(<Eye size={24} />, 'Eye');

export const EyeSlashIcon = createSvgIcon(<EyeSlash size={24} />, 'EyeSlash');

export const FadersHorizontalIcon = createSvgIcon(
  <FadersHorizontal size={24} />,
  'FadersHorizontal'
);

export const FileArrowUpIcon = createSvgIcon(
  <FileArrowUp size={24} />,
  'FileArrowUp'
);

export const FileCsvIcon = createSvgIcon(<FileCsv size={24} />, 'FileCsv');

export const FileMagnifyingGlassIcon = createSvgIcon(
  <FileMagnifyingGlass size={24} />,
  'FileMagnifyingGlass'
);

export const FilePdfIcon = createSvgIcon(<FilePdf size={24} />, 'FilePdf');

export const FilePlusIcon = createSvgIcon(<FilePlus size={24} />, 'FilePlus');

export const FilesIcon = createSvgIcon(<Files size={24} />, 'Files');

export const FileTextIcon = createSvgIcon(<FileText size={24} />, 'FileText');

export const FileXIcon = createSvgIcon(<FileX size={24} />, 'FileX');

export const FlagPennantIcon = createSvgIcon(
  <FlagPennant size={24} />,
  'FlagPennant'
);

export const FlaskIcon = createSvgIcon(<Flask size={24} />, 'Flask');

export const FloppyDiskIcon = createSvgIcon(
  <FloppyDisk size={24} />,
  'FloppyDisk'
);

export const FolderNotchIcon = createSvgIcon(
  <FolderNotch size={24} />,
  'FolderNotch'
);

export const ForkKnifeIcon = createSvgIcon(
  <ForkKnife size={24} />,
  'ForkKnife'
);

export const FunnelSimpleIcon = createSvgIcon(
  <FunnelSimple size={24} />,
  'FunnelSimple'
);

export const GavelIcon = createSvgIcon(<Gavel size={24} />, 'Gavel');

export const GearIcon = createSvgIcon(<Gear size={24} />, 'Gear');

export const GearSixIcon = createSvgIcon(<GearSix size={24} />, 'GearSix');

export const GiftIcon = createSvgIcon(<Gift size={24} />, 'Gift');

export const GlobeHemisphereWestIcon = createSvgIcon(
  <GlobeHemisphereWest size={24} />,
  'GlobeHemisphereWest'
);

export const HandCoinsIcon = createSvgIcon(
  <HandCoins size={24} />,
  'HandCoins'
);

export const HandsClappingIcon = createSvgIcon(
  <HandsClapping size={24} />,
  'HandsClapping'
);

export const HandshakeIcon = createSvgIcon(
  <Handshake size={24} />,
  'Handshake'
);

export const HardDrivesIcon = createSvgIcon(
  <HardDrives size={24} />,
  'HardDrives'
);

export const HourglassHighIcon = createSvgIcon(
  <HourglassHigh size={24} />,
  'HourglassHigh'
);

export const HourglassMediumIcon = createSvgIcon(
  <HourglassMedium size={24} />,
  'HourglassMedium'
);

export const IdentificationCardIcon = createSvgIcon(
  <IdentificationCard size={24} />,
  'IdentificationCard'
);

export const ImageIcon = createSvgIcon(<Image size={24} />, 'Image');

export const InfoIcon = createSvgIcon(<Info size={24} />, 'Info');

export const LaptopIcon = createSvgIcon(<Laptop size={24} />, 'Laptop');

export const LightningIcon = createSvgIcon(
  <Lightning size={24} />,
  'Lightning'
);

export const LinkBreakIcon = createSvgIcon(
  <LinkBreak size={24} />,
  'LinkBreak'
);

export const LinkIcon = createSvgIcon(<Link size={24} />, 'Link');

export const ListBulletsIcon = createSvgIcon(
  <ListBullets size={24} />,
  'ListBullets'
);

export const ListIcon = createSvgIcon(<List size={24} />, 'List');

export const LockIcon = createSvgIcon(<Lock size={24} />, 'Lock');

export const LockOpenIcon = createSvgIcon(<LockOpen size={24} />, 'LockOpen');

export const MagnifyingGlassIcon = createSvgIcon(
  <MagnifyingGlass size={24} />,
  'MagnifyingGlass'
);

export const MagnifyingGlassMinusIcon = createSvgIcon(
  <MagnifyingGlassMinus size={24} />,
  'MagnifyingGlassMinus'
);

export const MagnifyingGlassPlusIcon = createSvgIcon(
  <MagnifyingGlassPlus size={24} />,
  'MagnifyingGlassPlus'
);

export const MapPinIcon = createSvgIcon(<MapPin size={24} />, 'MapPin');

export const MegaphoneSimpleIcon = createSvgIcon(
  <MegaphoneSimple size={24} />,
  'MegaphoneSimple'
);

export const MinusIcon = createSvgIcon(<Minus size={24} />, 'Minus');

export const MoneyIcon = createSvgIcon(<Money size={24} />, 'Money');

export const MusicNotesIcon = createSvgIcon(
  <MusicNotes size={24} />,
  'MusicNotes'
);

export const NumberCircleOneIcon = createSvgIcon(
  <NumberCircleOne size={24} />,
  'NumberCircleOne'
);

export const PackageIcon = createSvgIcon(<Package size={24} />, 'Package');

export const PaletteIcon = createSvgIcon(<Palette size={24} />, 'Palette');

export const PaperclipIcon = createSvgIcon(
  <Paperclip size={24} />,
  'Paperclip'
);

export const PasswordIcon = createSvgIcon(<Password size={24} />, 'Password');

export const PencilSimpleIcon = createSvgIcon(
  <PencilSimple size={24} />,
  'PencilSimple'
);

export const PercentIcon = createSvgIcon(<Percent size={24} />, 'Percent');

export const PhoneCallIcon = createSvgIcon(
  <PhoneCall size={24} />,
  'PhoneCall'
);

export const PhoneIcon = createSvgIcon(<Phone size={24} />, 'Phone');

export const PiggyBankIcon = createSvgIcon(
  <PiggyBank size={24} />,
  'PiggyBank'
);

export const PlugsConnectedIcon = createSvgIcon(
  <PlugsConnected size={24} />,
  'PlugsConnected'
);

export const PlugsIcon = createSvgIcon(<Plugs size={24} />, 'Plugs');

export const PlusIcon = createSvgIcon(<Plus size={24} />, 'Plus');

export const ProhibitIcon = createSvgIcon(<Prohibit size={24} />, 'Prohibit');

export const ProjectorScreenChartIcon = createSvgIcon(
  <ProjectorScreenChart size={24} />,
  'ProjectorScreenChart'
);

export const ProjectorScreenIcon = createSvgIcon(
  <ProjectorScreen size={24} />,
  'ProjectorScreen'
);

export const QuestionIcon = createSvgIcon(<Question size={24} />, 'Question');

export const ReadCvLogoIcon = createSvgIcon(
  <ReadCvLogo size={24} />,
  'ReadCvLogo'
);

export const ReceiptIcon = createSvgIcon(<Receipt size={24} />, 'Receipt');

export const ReceiptXIcon = createSvgIcon(<ReceiptX size={24} />, 'ReceiptX');

export const RocketLaunchIcon = createSvgIcon(
  <RocketLaunch size={24} />,
  'RocketLaunch'
);

export const ShieldCheckIcon = createSvgIcon(
  <ShieldCheck size={24} />,
  'ShieldCheck'
);

export const ShieldWarningIcon = createSvgIcon(
  <ShieldWarning size={24} />,
  'ShieldWarning'
);

export const ShoppingCartIcon = createSvgIcon(
  <ShoppingCart size={24} />,
  'ShoppingCart'
);

export const SignOutIcon = createSvgIcon(<SignOut size={24} />, 'SignOut');

export const SlidersHorizontalIcon = createSvgIcon(
  <SlidersHorizontal size={24} />,
  'SlidersHorizontal'
);

export const SmileyIcon = createSvgIcon(<Smiley size={24} />, 'Smiley');

export const SplitHorizontalIcon = createSvgIcon(
  <SplitHorizontal size={24} />,
  'SplitHorizontal'
);

export const SquaresFourIcon = createSvgIcon(
  <SquaresFour size={24} />,
  'SquaresFour'
);

export const StarIcon = createSvgIcon(<Star size={24} />, 'Star');

export const StorefrontIcon = createSvgIcon(
  <Storefront size={24} />,
  'Storefront'
);

export const SuitcaseSimpleIcon = createSvgIcon(
  <SuitcaseSimple size={24} />,
  'SuitcaseSimple'
);

export const SwapIcon = createSvgIcon(<Swap size={24} />, 'Swap');

export const ThumbsUpIcon = createSvgIcon(<ThumbsUp size={24} />, 'ThumbsUp');

export const TrademarkRegisteredIcon = createSvgIcon(
  <TrademarkRegistered size={24} />,
  'TrademarkRegistered'
);

export const TrashIcon = createSvgIcon(<Trash size={24} />, 'Trash');

export const TrayIcon = createSvgIcon(<Tray size={24} />, 'Tray');

export const UploadSimpleIcon = createSvgIcon(
  <UploadSimple size={24} />,
  'UploadSimple'
);

export const UserCircleIcon = createSvgIcon(
  <UserCircle size={24} />,
  'UserCircle'
);

export const UserCirclePlusIcon = createSvgIcon(
  <UserCirclePlus size={24} />,
  'UserCirclePlus'
);

export const UserGearIcon = createSvgIcon(<UserGear size={24} />, 'UserGear');

export const UserIcon = createSvgIcon(<User size={24} />, 'User');

export const UserPlusIcon = createSvgIcon(<UserPlus size={24} />, 'UserPlus');

export const UsersIcon = createSvgIcon(<Users size={24} />, 'Users');

export const UsersThreeIcon = createSvgIcon(
  <UsersThree size={24} />,
  'UsersThree'
);

export const WalletIcon = createSvgIcon(<Wallet size={24} />, 'Wallet');

export const WarningCircleIcon = createSvgIcon(
  <WarningCircle size={24} />,
  'WarningCircle'
);

export const WarningDiamondIcon = createSvgIcon(
  <WarningDiamond size={24} />,
  'WarningDiamond'
);

export const WarningIcon = createSvgIcon(<Warning size={24} />, 'Warning');

export const XCircleIcon = createSvgIcon(<XCircle size={24} />, 'XCircle');

export const XIcon = createSvgIcon(<X size={24} />, 'X');

export const XSquareIcon = createSvgIcon(<XSquare size={24} />, 'XSquare');
